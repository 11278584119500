import * as Integrations from '../../helpers/integrations.js'

export const SYNC_RESTRICTION = {
  [Integrations.SOFTDENT_CLOUD]:
    'No sync restriction',
  [Integrations.MOCKPMS]:
    'No sync restriction',
  [Integrations.ORTHOTRAC_CLOUD]:
    'No sync restriction',
  [Integrations.CORNERSTONE]:
    'No sync restriction',
  [Integrations.ADVANTAGE]:
    'No sync restriction',
  [Integrations.CLIENTRAX]:
    'No sync restriction',
  [Integrations.COMPLETE_CLINIC]:
    'No sync restriction',
  [Integrations.DENTRIXG4_RAW]:
    'No sync restriction',
  [Integrations.EASY_DENTAL_RAW]:
    'No sync restriction',
  [Integrations.DENTRIXG5]:
    'No sync restriction',
  [Integrations.DSN]: 'No sync restriction',
  [Integrations.EAGLESOFT18]:
    'No sync restriction',
  [Integrations.ECW]: 'No sync restriction',
  [Integrations.EDDSON]:
    'No sync restriction',
  [Integrations.EYEFINITY]:
    'No sync restriction',
  [Integrations.IMPROMED]:
    'No sync restriction',
  [Integrations.INTRAVET]:
    'No sync restriction',
  [Integrations.MACPRACTICE]:
    'No sync restriction',
  [Integrations.MAXIMEYES]:
    'No sync restriction',
  [Integrations.MINDBODY]:
    'No sync restriction',
  [Integrations.MODMED]:
    'No sync restriction',
  [Integrations.EMA]: 'No sync restriction',
  [Integrations.NAVETOR]:
    'No sync restriction',
  [Integrations.NEXTECH]:
   'No sync restriction',
  [Integrations.OASYS]:
    'No sync restriction',
  [Integrations.ODLINK]:
    'No sync restriction',
  [Integrations.OFFICEMATE]:
    'No sync restriction',
  [Integrations.OMSVISION]:
    'No sync restriction',
  [Integrations.OPENDENTAL]:
    'No sync restriction',
  [Integrations.OPENDENTAL_CLOUD]:
    'No sync restriction',
  [Integrations.OPENDENTAL_LOCAL]:
    'No sync restriction',
  [Integrations.ORTHOTRAC]:
    'No sync restriction',
  [Integrations.DOLPHIN]:
    'No sync restriction',
  [Integrations.DOLPHINCLOUD]:
    'No sync restriction',
  [Integrations.DOLPHIN_BLUE]:
    'No sync restriction',
  [Integrations.DR_CHRONO]:
    'No sync restriction',
  [Integrations.EAGLESOFT16]:
    'No sync restriction',
  [Integrations.ORTHOTRAC_CLOUD_V2]:
    'No sync restriction',
  [Integrations.PATTERSON_FUSE]:
   'No sync restriction',
  [Integrations.PRACTICE_DIRECTOR]:
   'No sync restriction',
  [Integrations.PRACTICE_WORKS]:
   'No sync restriction',
  [Integrations.PROVET_CLOUD]:
   'No sync restriction',
  [Integrations.CSV]: 'No sync restriction',
  [Integrations.CUSTOM_CONTACT]:
    'No sync restriction',
  [Integrations.UNKNOWN]:
    'No sync restriction',
  [Integrations.ABELDENT]:
    'No sync restriction',
  [Integrations.ADVANCEDMD]:
    'The hours between 6:00 AM (Mountain Time) and 6:00 PM (Mountain Time) are peak hours of AdvancedMD and hence we do not sync during these hours. So peak hours are 12 - 23 UTC (inclusive). We treat Saturday and Sunday like the other days.',
  [Integrations.ATHENAHEALTH]:
    'No sync restriction',
  [Integrations.VERADIGM]:
    'No sync restriction',
  [Integrations.VERADIGM_CLOUD]:
    'No sync restriction',
  [Integrations.INFINITE_VT]:
    'No sync restriction',
  [Integrations.AVIMARK]:
    'No sync restriction',
  [Integrations.CLOUD9]:
    'Syncs can only run between 12:00:00 AM - 11:00:00 AM UTC (11 hours window)',
  [Integrations.COMPULINK]:
    'No sync restriction',
  [Integrations.DVM_MANAGER]:
   'No sync restriction',
  [Integrations.DVMAX]:
   'No sync restriction',
  [Integrations.HVMS]: 'No sync restriction',
  [Integrations.STRINGSOFT]:
   'No sync restriction',
  [Integrations.TRAKNET_CLOUD]:
   'No sync restriction',
  [Integrations.TRIPLE_CROWN]:
   'No sync restriction',
  [Integrations.VIA]: 'No sync restriction',
  [Integrations.CURVE]:
   'No sync restriction',
  [Integrations.MINDBODYV6]:
   'No sync restriction',
  [Integrations.SHEPHERD]:
  'No sync restriction',
  [Integrations.EZYVET]:
   'No sync restriction',
  [Integrations.DENTRIXG4]:
    'No sync restriction',
  [Integrations.EASY_DENTAL]:
    'No sync restriction',
  [Integrations.EMR4D]:
    'No sync restriction',
  [Integrations.EVETPRACTICE]:
   'No sync restriction',
  [Integrations.PRACTICEWORKS_CLOUD_V2]:
   'No sync restriction',
  [Integrations.QUICKBOOKS_ONLINE]:
    'No sync restriction',
  [Integrations.VTECH_PLATINUM]:
   'No sync restriction',
  [Integrations.CRYSTAL_PM]:
    'No sync restriction',
  [Integrations.DAYSMARTVET]:
   'No sync restriction',
  [Integrations.DENTICON]:
   'No sync restriction',
  [Integrations.DENTIMAX]:
    'No sync restriction',
  [Integrations.DENTRIX_ASCEND]:
    'No sync restriction',
  [Integrations.DENTRIX_ENTERPRISE]:
    'No sync restriction',
  [Integrations.NEXTECH_PRACTICE]:
   'No sync restriction',
  [Integrations.NEXTGEN]:
    'No sync restriction',
  [Integrations.ORTHO2]:
    'No sync restriction',
  [Integrations.REVOLUTION]:
    'No sync restriction',
  [Integrations.SOFTDENT16]:
    'No sync restriction',
  [Integrations.SOFTDENT_CLOUD_V2]:
    'No sync restriction',
  [Integrations.SYCLE]:
    'No sync restriction',
  [Integrations.TOTALMD]:
    'No sync restriction',
  [Integrations.TRAKNET]:
    'No sync restriction',
  [Integrations.UPRISE]:
    'No sync restriction',
  [Integrations.WEAVEPMS]:
    'No sync restriction',
  [Integrations.WINOMS]:
    'No sync restriction',
  [Integrations.WINOMS_CLOUD_V2]:
    'No sync restriction',
  [Integrations.CORNERSTONE_CLOUD]:
    '50k API calls per office per month',
  [Integrations.BOOKER]:
    'No sync restriction',
  [Integrations.PRACTICE_WORKS_CLOUD]:
    'No sync restriction',
  [Integrations.SENSEI]:
    'No sync restriction',
  [Integrations.WINOMS_CLOUD]:
    'No sync restriction',
  [Integrations.PROMPT]:
    'No sync restriction'
}
