import { createSlice } from '@reduxjs/toolkit'
import { makeGETAPIRequest } from '../../api'
import * as Helpers from '../../helpers/index'
import authnClient from '../../auth/auth'
import { BLACKLISTED, ENTITIES, WRITEBACKS, GA_DATES, TIERS, PMS_ADAPTERS } from '../../helpers/utils'

const JASPER_URL = process.env.REACT_APP_JASPER_URL
const WEAVE_URL = process.env.REACT_APP_WEAVE_URL

const bizSlice = createSlice({
  name: 'biz',
  initialState: {
    currVertical: '',
    currIntegration: '',
    loading: false,
    refreshing: false,
    refreshError: null,
    error: null
  },
  reducers: {
    setCurrentVertical (state, action) {
      state.currVertical = action.payload
    },

    setCurrentIntegration (state, action) {
      state.currIntegration = action.payload
    },

    setLoading (state, action) {
      state.loading = true
    },

    unsetLoading (state, action) {
      state.loading = false
    },

    setInfo (state, action) {
      state.integrations = action.payload
    },

    setSummary (state, action) {
      state.summary = action.payload
    },

    setAnalytics (state, action) {
      state.analytics = action.payload
    },

    setApiError (state, action) {
      state.error = action.payload
    },

    setRefreshing (state, action) {
      state.refreshing = true
    },

    unSetRefreshing (state, action) {
      state.refreshing = false
    },

    setRefreshError (state, action) {
      state.refreshError = action.payload
    }
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    loading: false,
    error: null,
    token: null
  },
  reducers: {
    authenticate (state, action) {
      state.isAuthenticated = true
      state.token = action.payload
    },

    logout (state, action) {
      state.isAuthenticated = false
      state.token = null
    },

    setLoading (state) {
      state.loading = true
    },

    unSetLoading (state) {
      state.loading = false
    },

    setErr (state, action) {
      state.error = action.payload
    }
  }
})

export const logout = () => {
  return async (dispatch) => {
    dispatch(authActions.logout())
    Helpers.removeLocalStorageItem(Helpers.TOKEN_KEY)
  }
}

const getWritebackFlag = (operation) => {
  if (operation?.some(x => ['CREATE', 'UPDATE'].includes(x))) return 'Yes'
  return 'No'
}

const getFlag = (operation) => {
  if (operation?.some(x => ['LIST'].includes(x))) return 'Yes'
  return 'No'
}

export const login = () => {
  return async (dispatch) => {
    try {
      dispatch(authActions.setErr(null))
      dispatch(authActions.setLoading())
      authnClient.changeAuthMethod('okta')
      try {
        await authnClient.signIn()
      } catch (err) {
        console.log(err)
        throw new Error('Unable to do okta login')
      }
      dispatch(authActions.unSetLoading())
      dispatch(authActions.setErr(null))
    } catch (err) {
      dispatch(authActions.unSetLoading())
      dispatch(authActions.setErr(err.message))
    }
  }
}

export const refresh = (token) => {
  return async (dispatch) => {
    dispatch(bizActions.setRefreshing())
    dispatch(bizActions.setRefreshError(null))
    try {
      const promises = []
      promises.push(parseIntegrationStatus(token), parseJasperAndWAM(token))
      const apiResponse = await Promise.all(promises)
      dispatch(bizActions.setInfo(apiResponse[0]))
      dispatch(bizActions.setAnalytics(apiResponse[1]))
      dispatch(bizActions.setSummary(parseSummary(apiResponse[0])))
      dispatch(bizActions.unSetRefreshing())
      Helpers.setLocalStorageItem(Helpers.LAST_UPDATE_TIME_KEY, Helpers.getNowTime())
    } catch (err) {
      dispatch(bizActions.unSetRefreshing())
      dispatch(bizActions.setRefreshError(err))
    }
  }
}

const cleanInstallType = (type) => {
  if (type === 'CLOUD') return 'Cloud'
  if (type === 'ON_PREM') return 'On Prem'
  return '-'
}

const parseSummary = (integrations) => {
  const ans = {}

  for (const intz in integrations) {
    if (BLACKLISTED[intz]) continue
    if (integrations[intz].status && integrations[intz].status !== 'AVAILABLE') continue
    const caps = integrations[intz].capabilities ?? []
    ans[intz] = {}
    ans[intz].caps = {}
    for (const cap of caps) {
      if (ENTITIES[cap.dataType]) {
        let x = ans[intz].caps[ENTITIES[cap.dataType]]
        if (x) {
          x = [...x, cap.operation]
        } else {
          x = [cap.operation]
        }
        ans[intz].caps[ENTITIES[cap.dataType]] = x
      }
      if (WRITEBACKS[cap.dataType]) {
        let x = ans[intz].caps[WRITEBACKS[cap.dataType]]
        if (x) {
          x = [...x, cap.operation]
        } else {
          x = [cap.operation]
        }
        ans[intz].caps[WRITEBACKS[cap.dataType]] = x
      }
    }
  }
  const res = []
  for (const key in ans) {
    const integration = integrations[key]
    const intz = {
      integration: integration.displayName,
      tier: TIERS[integration.displayName] ?? '-',
      type: cleanInstallType(integration.type),
      gaDate: (GA_DATES[integration.displayName] ?? new Date()).toString(),
      lastVerified: (GA_DATES[integration.displayName] ?? new Date()).toString(),
      patient: getFlag(ans[key].caps[ENTITIES.PERSON]),
      appointment: getFlag(ans[key].caps[ENTITIES.APPOINTMENT]),
      photo: getFlag(ans[key].caps[ENTITIES.PHOTO]),
      insurance: getFlag(ans[key].caps[ENTITIES.INSURANCE]),
      provider: getFlag(ans[key].caps[ENTITIES.PROVIDER]),
      aging: getFlag(ans[key].caps[ENTITIES.AGING]),
      contactInfo: getFlag(ans[key].caps[ENTITIES.APPOINTMENT_PROVIDER]),
      textNote: getFlag(ans[key].caps[ENTITIES.TEXT_NOTE]),
      appointmentStatus: getFlag(ans[key].caps[ENTITIES.APPOINTMENT_STATUS]),
      appointmentProvider: getFlag(ans[key].caps[ENTITIES.APPOINTMENT_PROVIDER]),
      appointmentType: getFlag(ans[key].caps[ENTITIES.APPOINTMENT_TYPE]),
      site: getFlag(ans[key].caps[ENTITIES.CLIENT_LOCATION]),
      recall: getFlag(ans[key].caps[ENTITIES.RECALL]),
      recallType: getFlag(ans[key].caps[ENTITIES.RECALL_TYPE]),
      payment: getFlag(ans[key].caps[ENTITIES.PAYMENT]),
      framesOrder: getFlag(ans[key].caps[ENTITIES.FRAMES_ORDER]),
      contactsOrder: getFlag(ans[key].caps[ENTITIES.CONTACTS_ORDER]),
      medicalHistory: getFlag(ans[key].caps[ENTITIES.MEDICAL_HISTORY]),
      operatory: getFlag(ans[key].caps[ENTITIES.OPERATORY]),
      document: getFlag(ans[key].caps[ENTITIES.DOCUMENT]),
      medicalConditions: getFlag(ans[key].caps[ENTITIES.MEDICAL_CONDITION]),
      procedureLog: getFlag(ans[key].caps[ENTITIES.PROCEDURE_LOG]),
      feeSchedule: getFlag(ans[key].caps[ENTITIES.FEE_SCHEDULE]),
      feeScheduleFee: getFlag(ans[key].caps[ENTITIES.FEE_SCHEDULE_FEE]),
      procedureCode: getFlag(ans[key].caps[ENTITIES.PROCEDURE_CODE]),
      appointmentProcedure: getFlag(ans[key].caps[ENTITIES.APPOINTMENT_PROCEDURE]),
      account: getFlag(ans[key].caps[ENTITIES.ACCOUNT]),
      pet: getFlag(ans[key].caps[ENTITIES.PET]),
      saveTheDate: getFlag(ans[key].caps[ENTITIES.SAVE_THE_DATE]),
      insurancePayerDetails: getFlag(ans[key].caps[ENTITIES.INSURANCE_PAYER_DETAILS]),
      insurancePlan: getFlag(ans[key].caps[ENTITIES.INSURANCE_PLAN]),
      insuranceDetails: getFlag(ans[key].caps[ENTITIES.INSURANCE_DETAILS]),
      appointmentWriteback: getWritebackFlag(ans[key].caps[WRITEBACKS.APPOINTMENT]),
      appointmentConfirmationWriteback: getWritebackFlag(ans[key].caps[WRITEBACKS.APPOINTMENT_CONFIRMATION]),
      paymentWriteback: getWritebackFlag(ans[key].caps[WRITEBACKS.PAYMENT]),
      medicalConditionWriteback: getWritebackFlag(ans[key].caps[WRITEBACKS.MEDICAL_CONDITION]),
      medicalHistoryWriteback: getWritebackFlag(ans[key].caps[WRITEBACKS.MEDICAL_HISTORY]),
      textWriteback: getWritebackFlag(ans[key].caps[WRITEBACKS.TEXT_NOTE]),
      patientWriteback: getWritebackFlag(ans[key].caps[WRITEBACKS.PERSON]),
      documentWriteback: getWritebackFlag(ans[key].caps[WRITEBACKS.DOCUMENT])
    }
    res.push(intz)
  }
  return res
}

export const getData = (token) => {
  return async (dispatch) => {
    dispatch(bizActions.setLoading())
    dispatch(bizActions.setApiError(null))
    try {
      const promises = []
      promises.push(parseIntegrationStatus(token), parseJasperAndWAM(token))
      const apiResponse = await Promise.all(promises)
      dispatch(bizActions.setInfo(apiResponse[0]))
      dispatch(bizActions.setAnalytics(apiResponse[1]))
      dispatch(bizActions.setSummary(parseSummary(apiResponse[0])))
      dispatch(bizActions.unsetLoading())
      Helpers.setLocalStorageItem(Helpers.LAST_UPDATE_TIME_KEY, Helpers.getNowTime())
    } catch (err) {
      console.log(err)
      dispatch(bizActions.unsetLoading())
      dispatch(bizActions.setApiError(err.message))
    }
  }
}

const parseIntegrationStatus = async (token) => {
  const ans = {}
  const res = await makeGETAPIRequest(WEAVE_URL, '/syncapp/integrations-service/integrations', token)
  for (const integration of res.integrations) {
    ans[integration.displayName] = integration
  }
  return ans
}

const parseJasperAndWAM = async (token) => {
  const jasperResponse = await makeGETAPIRequest(JASPER_URL, '/internal/list_all', token)
  // const wamResponse = await makeGETAPIRequest(`${WEAVE_URL}`, '/support/v1/locations/')
  return parseJasper(jasperResponse.data)
}

const parseJasper = (jasper) => {
  const analytics = {}
  const versions = {}
  const counts = {}

  Object.values(PMS_ADAPTERS).forEach((pms) => {
    counts[pms] = { active: 0, inactive: 0 }
    versions[pms] = {}
  })

  for (const slug of Object.keys(jasper)) {
    for (const connection of jasper[slug].connections ?? []) {
      const pms = PMS_ADAPTERS[connection.adapter_information.name]
      const version = connection.adapter_information.version

      versions[pms] = versions[pms] || {}
      versions[pms][version] = (versions[pms][version] || 0) + 1

      if (!counts[pms]) {
        continue
      }
    }
  }

  for (const pms of Object.values(PMS_ADAPTERS)) {
    const arr = []
    for (const v of Object.keys(versions[pms])) {
      arr.push({
        version: v,
        count: versions[pms][v]
      })
    }
    analytics[pms] = { versions: arr }
  }

  return analytics
}

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer

export const bizActions = bizSlice.actions
export const bizReducer = bizSlice.reducer
